import { theme } from '@ui/style'

interface Icon16ArrowLargeProps extends SVGComponentProps {
	direction: 'up' | 'down'
}

const arrowPathByDirection = {
	up: 'M1 11.5L8 4.5L15 11.5',
	down: 'M1 4.5L8 11.5L15 4.5',
}

export const Icon16ArrowLarge = ({ color, direction, className }: Icon16ArrowLargeProps) => {
	const iconColor = color || theme.color.colBlack
	const arrowPath = arrowPathByDirection[direction]
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			className={className}
		>
			<path d={arrowPath} stroke={iconColor} strokeWidth="1.8" />
		</svg>
	)
}
