'use client'

interface IconArrowFooterProps extends SVGComponentProps {
	direction: 'up' | 'down'
}
const IconArrowFooter = ({ direction = 'up' }: IconArrowFooterProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			{direction === 'up' ? (
				<path d="M4 10L8 6L12 10" stroke="#777777" />
			) : (
				<path d="M4 6L8 10L12 6" stroke="#777777" />
			)}
		</svg>
	)
}

export default IconArrowFooter
