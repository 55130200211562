'use client'

import { Box, styled } from '@mui/joy'
import { usePathname } from 'next/navigation'
import { useLayoutEffect, useState } from 'react'
import HomeMoAd from './HomeMoAd'
import HomePcAd from './HomePcAd'
import ArticleMoAd from './ArticleMoAd'
import UIButton from '@components/ui/UIButton'
import UIText from '@components/ui/UIText'
import pxToRem from '@utils/pxToRem'
import { theme } from '@ui/style'
import Icon16CloseTrue16 from 'src/assets/icons/Icon16CloseTrue16'

interface GNBBannerProps {
	isApp: boolean
}
const GNBBanner = ({ isApp }: GNBBannerProps) => {
	const pathname = usePathname()
	const [adType, setAdType] = useState<string>('')
	const [isDisplay, setIsDisplay] = useState(true)
	const closeBanner = () => {
		setIsDisplay(false)
	}
	useLayoutEffect(() => {
		if (pathname === '/') {
			setAdType('home')
		} else if (pathname.includes('/article')) {
			setAdType('article')
		} else {
			setAdType('')
		}
		setIsDisplay(true)
		return () => {
			document.querySelector('#article-gnb-mo')?.remove()
		}
	}, [pathname])

	const CloseButton = styled('button')(() => ({
		width: pxToRem(16),
		height: pxToRem(16),
		background: 'transparent',
		position: 'absolute',
		top: '50%',
		right: pxToRem(14),
		marginTop: pxToRem(-8),
		[theme.breakpoints.up('md')]: {
			padding: pxToRem(2),
			marginTop: pxToRem(-10),
			right: pxToRem(26),
		},
		[theme.breakpoints.up('xl')]: {
			right: 'calc((100% - 1216px) / 2)',
		},
	}))

	if (!isApp) {
		if (adType === 'home') {
			// return (
			// 	isDisplay && (
			// 		<Box overflow="hidden" bgcolor="#f8f8f8" position="relative">
			// 			<HomePcAd />
			// 			<HomeMoAd />
			// 			<CloseButton onClick={() => closeBanner()}>
			// 				<UIText readonly>닫기</UIText>
			// 				<Icon16CloseTrue16 />
			// 			</CloseButton>
			// 		</Box>
			// 	)
			// )
			return null
		}
		if (adType === 'article') {
			return (
				isDisplay && (
					<Box position="relative" bgcolor="#f8f8f8">
						<ArticleMoAd />
					</Box>
				)
			)
		}
	}
	return null
}
export default GNBBanner
