import { useCallback } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { appInterface } from './isApp'

interface TTSConfig {
	isTTSDraw: boolean
	isTTSPlaying: boolean
}

export const TTSState = atom<TTSConfig>({
	key: '#TTSState',
	default: {
		isTTSDraw: false,
		isTTSPlaying: false,
	},
})

export const useTTSControl = () => {
	const [state, setState] = useRecoilState(TTSState)
	const nativeEvent = useRecoilValue(appInterface)

	const setIsTTSDraw = useCallback(
		(value?: boolean) => {
			if (value) {
				setState((prev) => ({
					...prev,
					isTTSDraw: value,
				}))
			} else {
				setState((prev) => ({
					...prev,
					isTTSDraw: false,
				}))
			}
		},
		[setState],
	)
	const setIsTTSPlaying = useCallback(
		(value?: boolean) => {
			if (value) {
				setState((prev) => ({
					...prev,
					isTTSPlaying: value,
				}))
			} else {
				setState((prev) => ({
					...prev,
					isTTSPlaying: false,
				}))
				nativeEvent({ key: 'endTTS', value: null })
			}
		},
		[nativeEvent, setState],
	)

	return {
		isTTSDraw: state.isTTSDraw,
		isTTSPlaying: state.isTTSPlaying,
		setIsTTSDraw,
		setIsTTSPlaying,
	}
}
