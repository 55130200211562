import { ApiResultVoListHierarchyMenuResponseVo } from '@schemas/non-auth'
import { useQuery } from '@tanstack/react-query'
import { queryPrefetch } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'

export type MenuBottomResponse = CommonResponse<ApiResultVoListHierarchyMenuResponseVo>

const path = '/restapi/v1/get/system/menu/bottom'
const queryKeyString = () => ['@system/menu/bottom'] as const

const getQuery = async (): Promise<ApiResultVoListHierarchyMenuResponseVo> => {
	return fetchAPI({ path })
}

export const queryMenuBottom = () => ({
	queryKey: queryKeyString(),
	queryFn: () => getQuery(),
})

export const useMenuBottom = () => {
	const { data, ...rest } = useQuery(queryMenuBottom())
	return { data: (data as ApiResultVoListHierarchyMenuResponseVo)?.data, ...rest }
}
export const useMenuBottomQuery = (
	input?: CommonUseQueryInput<ApiResultVoListHierarchyMenuResponseVo, unknown>,
) => {
	const { data, ...rest } = useQuery({
		queryKey: [...queryKeyString()],
		queryFn: () => getQuery(),
		...input?.opt,
	})
	return { data, ...rest }
}
export const prefetchMenuBottom = () => queryPrefetch(queryMenuBottom())
