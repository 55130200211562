'use client'

import UIIconButton from '@components/ui/UIIconButton'
import UIText from '@components/ui/UIText'
import { useAodControl } from '@store/aod'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import color from '@ui/style/color.theme'
import { IconArrowTop } from 'src/assets/icons/IconArrowTop'
import { usePathname } from 'next/navigation'
import useIsScrollDown from '@page/article/_components/ArticleHeader/StickyHeader/hooks/useIsScrollDown'

interface ButtonGoToTopProps {
	isApp: boolean
}
const ButtonGoToTop = ({ isApp }: ButtonGoToTopProps) => {
	const { darkMode } = useDarkModeControl()
	const { aod } = useAodControl()
	const pathname = usePathname()
	const isArticle = pathname.includes('/article')
	const isScrollDown = useIsScrollDown()
	const isArticleScrollDown = isArticle && isScrollDown
	const handleScrollTop = () => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' })
	}

	return (
		<UIIconButton
			className="button-go2top"
			onClick={handleScrollTop}
			sx={(theme) => ({
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'fixed',
				zIndex: 100,
				right: { xs: theme.pxToRem(20), md: theme.pxToRem(28), xl: theme.pxToRem(48) },
				bottom: {
					xs: theme.pxToRem(
						20 +
							(aod.playAodIdx ? 78 : 0) +
							(isApp ? (isArticleScrollDown ? 0 : 65) : 0),
					),
					md: theme.pxToRem(
						28 +
							(aod.playAodIdx ? 78 : 0) +
							(isApp ? (isArticleScrollDown ? 0 : 65) : 0),
					),
					xl: theme.pxToRem(
						28 +
							(aod.playAodIdx ? 78 : 0) +
							(isApp ? (isArticleScrollDown ? 0 : 65) : 0),
					),
				},
				width: theme.pxToRem(56),
				height: theme.pxToRem(56),
				padding: theme.pxToRem(19),
				borderRadius: theme.pxToRem(28),
				backgroundColor: darkMode ? '#444' : theme.color.colWhite,
				opacity: 0,
				boxShadow: 'none',
				pointerEvents: 'none',
				transition: 'opacity 150ms, boxShadow 150ms, backgroundColor 150ms',
				'.set-sticky ~ &': {
					opacity: 1,
					boxShadow: `0px ${theme.pxToRem(4)} ${theme.pxToRem(
						8,
					)} 0px rgba(0, 0, 0, 0.10)`,
					pointerEvents: 'auto',
					'&:active': {
						backgroundColor: darkMode ? theme.color.colGray5 : theme.color.colGray1,
					},
					[theme.mediaQueries.hover]: {
						'&:hover': {
							backgroundColor: darkMode ? theme.color.colGray5 : theme.color.colGray1,
						},
					},
				},
			})}
		>
			<IconArrowTop color={darkMode ? color.colGray1 : color.colBlack} />
			<UIText readonly>맨 위로</UIText>
		</UIIconButton>
	)
}

export default ButtonGoToTop
