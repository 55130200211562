'use client'

import { useLocalStorage } from '@hooks/useLocalStorage'
import { ArticleSizeState, useArticleSizeControl } from '@store/articleSize'
import { useAutoPlayControl } from '@store/autoPlay'
import { useEffect } from 'react'

const ArticleEvent = () => {
	const [localArticleSize] = useLocalStorage<ArticleSizeState['articleSize']>('article_size')
	const [localAutoPlay] = useLocalStorage<string>('auto_play')
	const { setArticleSize } = useArticleSizeControl()
	const { setAutoPlay } = useAutoPlayControl()

	useEffect(() => {
		if (localArticleSize) {
			setArticleSize(localArticleSize as ArticleSizeState['articleSize'])
		}
	}, [localArticleSize])

	useEffect(() => {
		if (localAutoPlay) {
			setAutoPlay(localAutoPlay === 'true')
		}
	}, [localAutoPlay])

	return null
}

export default ArticleEvent
