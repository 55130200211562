'use client'

import color from '@ui/style/color.theme'

const IconTabBarTTS = ({ isActive }: { isActive?: boolean }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M5.25 20.1266V12.0409H9.76995L15.6459 7.99805V24.1694L9.76995 20.1266H5.25Z"
				fill={isActive ? color.colPointBlue : ''}
				stroke={isActive ? color.colPointBlue : color.colBlack}
				strokeWidth="1.8"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.6895 26.1499C24.7429 25.5735 28.6685 21.2827 28.6685 16.075C28.6685 10.8672 24.7429 6.57637 19.6895 6V7.81444C23.746 8.37975 26.8685 11.8627 26.8685 16.075C26.8685 20.2872 23.746 23.7702 19.6895 24.3355V26.1499ZM19.6895 20.884C21.8614 20.3633 23.4757 18.4088 23.4757 16.0772C23.4757 13.7456 21.8614 11.7911 19.6895 11.2704V13.154C20.8529 13.6147 21.6757 14.7499 21.6757 16.0772C21.6757 17.4046 20.8529 18.5397 19.6895 19.0004V20.884Z"
				fill={isActive ? color.colPointBlue : color.colBlack}
			/>
		</svg>
	)
}

export default IconTabBarTTS
