import UIFlex from '@components/ui/UIFlex'
import { Alert } from '@mui/joy'
import { theme } from '@ui/style'
import { SnackbarContent, type CustomContentProps } from 'notistack'
import { forwardRef } from 'react'

declare module 'notistack' {
	interface VariantOverrides {
		normal: true
	}
}

interface ErrorNotificationProps extends CustomContentProps {}

/**
 * @description `SnackbarContent` => HTMLDivElement
 */

export const ErrorNotification = forwardRef<HTMLDivElement, ErrorNotificationProps>(
	({ message }, ref) => {
		return (
			<SnackbarContent ref={ref} role="alert">
				<UIFlex alignItems="center" justifyContent="center" minWidth={theme.pxToRem(288)}>
					<Alert
						color="danger"
						variant="outlined"
						sx={{
							minWidth: theme.pxToRem(288),
							justifyContent: 'center',
							textAlign: 'center',
							whiteSpace: 'pre-line',
							transform: {
								xs: `translateY(-${theme.pxToRem(78)})`,
								md: `translateY(-${theme.pxToRem(72)})`,
							},
						}}
					>
						{message}
					</Alert>
				</UIFlex>
			</SnackbarContent>
		)
	},
)
