'use client'

import Box from '@mui/joy/Box'
import UIContainer from '@components/ui/UIContainer'
import SitemapCategory from './Category'
import SitemapAccordion from './Accordion'
import { HierarchyMenuResponseWrapperVo } from '@schemas/non-auth'

export interface FooterSitemapProps {
	menu: HierarchyMenuResponseWrapperVo
}
const FooterSitemap = ({ menu }: FooterSitemapProps) => {
	return (
		<UIContainer>
			<Box display={{ xs: 'none', lg: 'block' }}>
				<SitemapCategory menu={menu} />
			</Box>
			<Box display={{ xs: 'block', lg: 'none' }}>
				<SitemapAccordion menu={menu} />
			</Box>
		</UIContainer>
	)
}

export default FooterSitemap
