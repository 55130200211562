import { forwardRef } from 'react'
import { SnackbarContent, type CustomContentProps } from 'notistack'
import Alert from '@mui/joy/Alert'
import UIFlex from '@components/ui/UIFlex'
import { theme } from '@ui/style'
import { useAodControl } from '@store/aod'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { useIsAppControl } from '@store/isApp'

declare module 'notistack' {
	interface VariantOverrides {
		normal: true
	}
}

interface SuccessNotificationProps extends CustomContentProps {}

/**
 * @description `SnackbarContent` => HTMLDivElement
 */

export const SuccessNotification = forwardRef<HTMLDivElement, SuccessNotificationProps>(
	({ message }, ref) => {
		const { aod } = useAodControl()
		const { isApp } = useIsAppControl()
		const { darkMode } = useDarkModeControl()
		return (
			<SnackbarContent
				ref={ref}
				role="alert"
				style={{
					justifyContent: 'center',
				}}
			>
				<UIFlex alignItems="center" justifyContent="center" minWidth={theme.pxToRem(288)}>
					<Alert
						color="neutral"
						variant="outlined"
						sx={{
							maxWidth: theme.pxToRem(360),
							justifyContent: 'center',
							textAlign: 'center',
							whiteSpace: 'pre-line',
							transform: {
								xs: `translateY(-${theme.pxToRem(
									(aod.playAodIdx ? 78 : 0) + (isApp ? 64 : 0),
								)})`,
								md: `translateY(-${theme.pxToRem(
									(aod.playAodIdx ? 72 : 0) + (isApp ? 64 : 0),
								)})`,
							},
							// notify 스타일 추가: 피그마 > 서브페이지 > RSS 서비스
							padding: `${theme.pxToRem(18)} ${theme.pxToRem(24)}`,
							borderRadius: {
								xs: theme.pxToRem(78),
								md: theme.pxToRem(72),
							},
							border: 'none',
							backgroundColor: darkMode
								? 'rgba(255,255,255,0.75)'
								: 'rgba(0, 0, 0, 0.75)',
							color: darkMode ? theme.color.colBlack : theme.color.colWhite,
							fontWeight: 600,
							fontSize: theme.pxToRem(14),
							lineHeight: theme.pxToRem(18),
						}}
					>
						{message}
					</Alert>
				</UIFlex>
			</SnackbarContent>
		)
	},
)
