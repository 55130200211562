'use client'

import { theme } from '@ui/style'

const IconTabBarPrev = ({ color = theme.color.colBlack }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path d="M19.5 7.5L11 16L19.5 24.5" stroke={color} strokeWidth="1.8" />
		</svg>
	)
}

export default IconTabBarPrev
