'use client'

import UIFlex from '@components/ui/UIFlex'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import color from '@ui/style/color.theme'
import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

interface RootLayoutWrapperProps {
	children: React.ReactNode
}
const RootLayoutWrapper = ({ children }: RootLayoutWrapperProps) => {
	const pathname = usePathname()
	const winHeight = useRef(0)
	const setWinHeight = () => {
		const height = window.innerHeight
		winHeight.current = height
	}
	useEffect(() => {
		window.history.scrollRestoration = 'manual'
		window?.addEventListener('resize', setWinHeight)
		return () => {
			window?.removeEventListener('resize', setWinHeight)
		}
	}, [])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'
		window.scrollTo(0, 0)
	}, [pathname])

	const { darkMode } = useDarkModeControl()
	return (
		<UIFlex
			id="wrapper"
			sx={{ minHeight: '100%' }}
			bgcolor={darkMode ? color.colBlack : 'transparent'}
		>
			{children}
		</UIFlex>
	)
}

export default RootLayoutWrapper
