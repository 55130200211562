'use client'

import { useTTSControl } from '@store/tts'
import color from '@ui/style/color.theme'
import IconTabBarTTS from 'src/assets/icons/IconTabBarTTS'
import { BarButton, ButtonText } from '../TabBarRoot'
import { useNotification } from '@lib/notification'
import { UITextProps } from '@components/ui/UIText'

const TTSController = ({ textColor }: { textColor: UITextProps['color'] }) => {
	const { setIsTTSDraw, isTTSPlaying, setIsTTSPlaying } = useTTSControl()
	const { notify } = useNotification()

	const handleTTS = () => {
		if (!isTTSPlaying) {
			setIsTTSDraw(true)
		} else {
			setIsTTSPlaying(false)
			notify('본문 듣기를 종료 하였습니다.')
		}
	}
	return (
		<BarButton onClick={handleTTS}>
			<IconTabBarTTS isActive={isTTSPlaying} />
			<ButtonText color={isTTSPlaying ? color.colPointBlue : textColor}>기사듣기</ButtonText>
		</BarButton>
	)
}
export default TTSController
