import { keyframes } from '@emotion/react'
import { styled } from '@mui/joy/styles'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import UIText from '@components/ui/UIText'
import UIFlex from '@components/ui/UIFlex'
import UIIconButton from '@components/ui/UIIconButton'
import { Box } from '@mui/joy'

export const newsSlideUp = keyframes`
  from {
  }
  to {
    transform: translateY(-100%);
  }
`
export const NewsLayout = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	height: theme.pxToRem(44),
	backgroundColor: theme.color.colDarkBlue,
	[theme.breakpoints.up('md')]: {
		height: theme.pxToRem(48),
	},
}))
export const NewsContainer = styled(UIFlex)(({ theme }) => ({
	paddingTop: theme.pxToRem(13),
	paddingBottom: theme.pxToRem(13),
	alignItems: 'flex-start',
	[theme.breakpoints.up('md')]: {
		paddingTop: theme.pxToRem(12),
		paddingBottom: theme.pxToRem(12),
	},
}))
export const NewsBox = styled(UIFlex)(({ theme }) => ({
	// width: `calc(100% - ${theme.pxToRem(68)})`,
	flexGrow: 1,
	marginRight: theme.pxToRem(16),
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(18),
	[theme.breakpoints.up('md')]: {
		width: `calc(100% - ${theme.pxToRem(86)})`,
		marginRight: theme.pxToRem(32),
		lineHeight: theme.pxToRem(24),
		'&> *:not(:first-child)': { marginLeft: theme.pxToRem(24) },
	},
}))

export const NewsText = styled(UIText)(({ theme }) => ({
	fontSize: 'inherit',
	lineHeight: 'inherit',
	color: theme.color.colWhite,
	wordBreak: 'break-all',
}))

export const NewsList = styled(List)(({ theme }) => ({
	display: 'flex',
	margin: 0,
	padding: 0,
	fontSize: 'inherit',
	lineHeight: 'inherit',
	maxHeight: '50vh',
	overflow: 'auto',
	'&> *:not(:first-child)': { marginTop: theme.pxToRem(12) },
}))
export const NewsItem = styled(ListItem)(({ theme }) => ({
	margin: 0,
	padding: 0,
	fontSize: 'inherit',
	lineHeight: 'inherit',
	minBlockSize: 'auto',
	'&.animate': {
		animation: `${newsSlideUp} 1s forwards`,
	},
}))

export const NewsActionBox = styled(UIFlex)(({ theme }) => ({
	flexDirection: 'row',
	paddingTop: theme.pxToRem(1),
	paddingBottom: theme.pxToRem(1),
	flexShrink: 0,
	'&> *:not(:first-child)': { marginLeft: theme.pxToRem(10) },
	[theme.breakpoints.up('md')]: {
		paddingTop: theme.pxToRem(4),
		paddingBottom: theme.pxToRem(4),
		'&> *:not(:first-child)': { marginLeft: theme.pxToRem(12) },
	},
}))

export const ActionButton = styled(UIIconButton)(({ theme }) => ({
	margin: theme.pxToRem(-10),
	padding: theme.pxToRem(10),
	[theme.breakpoints.up('md')]: {
		margin: theme.pxToRem(-12),
		padding: theme.pxToRem(12),
	},
	'&:hover': {
		backgroundColor: 'unset',
	},
	'&:active': {
		backgroundColor: 'unset',
	},
}))

export const NewsRollBox = styled(List)(({ theme }) => ({
	margin: 0,
	padding: 0,
	height: theme.pxToRem(18),
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(18),
	overflow: 'hidden',
	[theme.breakpoints.up('md')]: {
		height: theme.pxToRem(24),
		lineHeight: theme.pxToRem(24),
	},
}))
