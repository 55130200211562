'use client'

import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import useIsMounted from '@hooks/useIsMounted'
import { useMedia } from '@hooks/useMedia'
import Box from '@mui/joy/Box'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import dynamic from 'next/dynamic'
import { IconGoIcons } from 'src/assets/icons/IconGoIcons'
import NoticeText from './styled'
import { theme } from '@ui/style'

const FooterNotice = () => {
	const isMounted = useIsMounted()
	const isMediumDevice = useMedia('md')
	const { darkMode } = useDarkModeControl()
	const DynamicNoticeItem = dynamic(() => import('./NoticeItem'), {
		loading: () => null,
	})
	return (
		<UIContainer>
			<UIFlex
				align="center"
				direction="row"
				py={{
					xs: pxToRem(16),
					lg: pxToRem(15),
				}}
				sx={{ '&> *:not(:first-child)': { marginLeft: pxToRem(24) } }}
			>
				<Box flexShrink={0}>
					<NoticeText
						color={darkMode ? color.colGray1 : color.colBlack}
						sx={{ overflowWrap: 'unset', fontWeight: 700 }}
					>
						공지사항
					</NoticeText>
				</Box>
				<Box
					flexGrow="1"
					sx={{
						width: `calc(100% - ${theme.pxToRem(114)})`,
						[theme.breakpoints.up('lg')]: {
							width: `calc(100% - ${theme.pxToRem(128)})`,
						},
					}}
				>
					{isMounted && <DynamicNoticeItem />}
				</Box>
				<UILink
					display="flex"
					alignItems="center"
					justifyContent="center"
					href="/board/notice?page=1"
					scroll={false}
				>
					<UIText readonly>더 보기</UIText>
					{/* medium desktop(1024px~) 부터 size="md"로 prop 변경 필요 */}
					<IconGoIcons
						size={isMediumDevice ? 'sm' : 'md'}
						color={darkMode ? color.colGray1 : color.colBlack}
					/>
				</UILink>
			</UIFlex>
		</UIContainer>
	)
}
export default FooterNotice
