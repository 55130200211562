'use client'

import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import zIndexes from '@ui/style/zIndexes.theme'
import pxToRem from '@utils/pxToRem'

interface HeaderDimmedProps {
	onClick?: () => void
}

const HeaderDimmed = ({ onClick }: HeaderDimmedProps) => {
	const { darkMode } = useDarkModeControl()
	return (
		<Box
			position="fixed"
			top={pxToRem(120)}
			left={0}
			right={0}
			bottom={0}
			width="100%"
			height="100%"
			zIndex={zIndexes.header - 1}
			bgcolor={darkMode ? '#000' : color.colBlack}
			sx={{
				opacity: darkMode ? 0.6 : 0.7,
			}}
			onClick={onClick}
		/>
	)
}

export default HeaderDimmed
