'use client'

import { Box } from '@mui/joy'
import pxToRem from '@utils/pxToRem'
import { usePathname } from 'next/navigation'
import { ReactNode } from 'react'

interface ContentMinHeighterProps {
	children: ReactNode
}
const ContentMinHeighter = ({ children }: ContentMinHeighterProps) => {
	const pathname = usePathname()
	const isShorts = pathname.includes('/shorts')
	return isShorts ? (
		children
	) : (
		<Box
			className="content-minHeighter"
			minHeight={{
				xs: pxToRem(348),
				md: pxToRem(390),
				lg: pxToRem(472),
				xl: pxToRem(468),
			}}
		>
			{children}
		</Box>
	)
}

export default ContentMinHeighter
