import { theme } from '@ui/style'

const IconTabBarHome = ({ color = theme.color.colBlack }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M16 6.5L25.5 12.8333V25.5H18.85V18.9125H13.15V25.5H6.5V12.8333L16 6.5Z"
				stroke={color}
				strokeWidth="1.8"
			/>
		</svg>
	)
}

export default IconTabBarHome
