'use client'

import { SnackbarProvider, type SnackbarProviderProps } from 'notistack'
import { SuccessNotification } from './components/Success'
import { ErrorNotification } from './components/Error'

type NotificationProviderProps = React.StrictPropsWithChildren<{
	Components?: SnackbarProviderProps['Components']
}>

export const NotificationProvider = ({ Components, children }: NotificationProviderProps) => {
	const exitDuration = 195
	return (
		<SnackbarProvider
			maxSnack={3}
			anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			autoHideDuration={2000}
			transitionDuration={{
				exit: exitDuration,
			}}
			onEnter={(target: HTMLElement) => {
				target.style.opacity = '1'
			}}
			onExit={(target: HTMLElement) => {
				target.style.transition = target.style.transition.replace('transform ', '')
				target.style.opacity = '0'
			}}
			hideIconVariant
			Components={{
				success: SuccessNotification,
				error: ErrorNotification,
				...Components,
			}}
		>
			{children}
		</SnackbarProvider>
	)
}
