'use client'

import { useEffect, useState } from 'react'
import AccordionGroup from '@mui/joy/AccordionGroup'
import ListItem from '@mui/joy/ListItem'
import UIFlex from '@components/ui/UIFlex'
import { Icon16ArrowTiny } from 'src/assets/icons/Icon16ArrowTiny'
import { theme } from '@ui/style'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import {
	AccordionAnchor,
	AccordionBox,
	AccordionDetailBox,
	AccordionDetailContent,
	AccordionTitle,
	AccordionItem,
} from './styled'
import { FooterSitemapProps } from '.'
import { List } from '@mui/joy'

const SitemapAccordion = ({ menu }: FooterSitemapProps) => {
	const { highlightList, topMenusWithSubMenus, topMenusWithoutSubMenus } = menu
	const { darkMode } = useDarkModeControl()
	const [current, setCurrent] = useState<number | null>(null)
	useEffect(() => {
		return () => {
			setCurrent(null)
		}
	}, [])
	const moHighlightList = highlightList?.filter((item) => item.isMoHighlight)

	// const menuList = menu.map((children, index) => ({ children, index }))
	return (
		<UIFlex component={List} flexDirection="row" flexWrap="wrap" padding={0}>
			{moHighlightList?.map((item, index) => {
				return (
					<UIFlex
						key={`item-${item.menuIdx}`}
						component={ListItem}
						p="0"
						width={
							index === moHighlightList.length - 1 && index % 2 === 0 ? '100%' : '50%'
						}
						alignItems="stretch"
						sx={{
							paddingRight: index % 2 === 0 ? theme.pxToRem(8) : 0,
							paddingLeft: index % 2 === 1 ? theme.pxToRem(8) : 0,
							borderTop: '1px solid',
							borderTopColor: darkMode ? '#303030' : theme.color.colGray2,
						}}
					>
						<UILink link={item.linkUrlInfo}>
							<UIText
								sx={{
									'&': {
										display: 'block',
										paddingTop: theme.pxToRem(16),
										paddingBottom: theme.pxToRem(16),
										fontSize: theme.pxToRem(15),
										fontWeight: 600,
										lineHeight: theme.pxToRem(18),
										color: theme.color.colGray5,
									},
								}}
							>
								{item.menuName}
							</UIText>
						</UILink>
					</UIFlex>
				)
			})}
			<UIFlex
				component={ListItem}
				p="0"
				width="100%"
				direction="column"
				align="stretch"
				sx={{ '&> *:not(:first-child)': { marginLeft: theme.pxToRem(12) } }}
			>
				<AccordionGroup>
					{topMenusWithSubMenus?.map((item, index) => (
						<AccordionBox
							key={`column-${item.menuIdx}`}
							darkMode={darkMode}
							expanded={index === current}
							onChange={(event, expanded) => {
								setCurrent(expanded ? index : null)
							}}
						>
							<AccordionTitle
								indicator={
									<Icon16ArrowTiny
										direction="down"
										color={
											darkMode ? theme.color.colGray1 : theme.color.colBlack
										}
									/>
								}
							>
								{item.menuName}
							</AccordionTitle>
							<AccordionDetailBox>
								<AccordionDetailContent>
									{item.lowerMenu.map((lowerMenu) => (
										<AccordionItem key={lowerMenu.menuIdx}>
											<AccordionAnchor link={lowerMenu.linkUrlInfo}>
												{lowerMenu.menuName}
											</AccordionAnchor>
										</AccordionItem>
									))}
								</AccordionDetailContent>
							</AccordionDetailBox>
						</AccordionBox>
					))}
				</AccordionGroup>
			</UIFlex>
			{topMenusWithoutSubMenus?.map((item, index) => {
				return (
					<UIFlex
						key={`item-${item.menuIdx}`}
						component={ListItem}
						p="0"
						width={
							index === topMenusWithoutSubMenus.length - 1 && index % 2 === 0
								? '100%'
								: '50%'
						}
						alignItems="stretch"
						sx={{
							paddingRight: index % 2 === 0 ? theme.pxToRem(8) : 0,
							paddingLeft: index % 2 === 1 ? theme.pxToRem(8) : 0,
							borderTop: '1px solid',
							borderTopColor: darkMode ? '#303030' : theme.color.colGray2,
						}}
					>
						<UILink link={item.linkUrlInfo}>
							<UIText
								sx={{
									'&': {
										display: 'block',
										paddingTop: theme.pxToRem(15),
										paddingBottom: theme.pxToRem(16),
										fontSize: theme.pxToRem(15),
										fontWeight: 600,
										lineHeight: theme.pxToRem(18),
										color: theme.color.colGray5,
									},
								}}
							>
								{item.menuName}
							</UIText>
						</UILink>
					</UIFlex>
				)
			})}
		</UIFlex>
	)
}
export default SitemapAccordion
