'use client'

import { theme } from '@ui/style'

const IconTabBarNext = ({ color = theme.color.colBlack }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path d="M12.5 7.5L21 16L12.5 24.5" stroke={color} strokeWidth="1.8" />
		</svg>
	)
}

export default IconTabBarNext
