import { Modal, ModalProps, styled } from '@mui/joy'
import zIndexes from '@ui/style/zIndexes.theme'

export type UISimpleModalRootProps = ModalProps

const StyledModal = styled(Modal)(({ theme }) => {
	return {
		zIndex: zIndexes.fullModal,
		'& .MuiModal-backdrop': {
			backdropFilter: 'none',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
		},
	}
})

const UISimpleModalRoot = ({ ...rest }: UISimpleModalRootProps) => {
	return <StyledModal {...rest} />
}

export default UISimpleModalRoot
