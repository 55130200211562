import { useCallback } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

interface IsFocusedState {
	isFocused?: boolean
}

export const isFocusedState = atom<IsFocusedState>({
	key: '#isFocused',
	default: {
		isFocused: false,
	},
})

export const useIsFocusedControl = () => {
	const [state, setState] = useRecoilState(isFocusedState)

	const setIsFocused = useCallback(
		(value?: boolean) => {
			if (value) {
				setState({ isFocused: value })
			} else {
				setState({ isFocused: false })
			}
		},
		[setState],
	)

	return { isFocused: state.isFocused, setIsFocused }
}

export const useSetIsFocused = () => {
	const setState = useSetRecoilState(isFocusedState)
	return (value?: boolean) => {
		if (value) {
			setState({ isFocused: value })
		} else {
			setState({ isFocused: false })
		}
	}
}

export const useGetIsFocused = () => {
	const state = useRecoilValue(isFocusedState)
	return state.isFocused
}
