'use client'

import Box from '@mui/joy/Box'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import UIFlex from '@components/ui/UIFlex'
import { theme } from '@ui/style'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { CategoryAnchor, CategoryBox, CategoryTitle } from './styled'
import { FooterSitemapProps } from '.'

const SitemapCategory = ({ menu }: FooterSitemapProps) => {
	// const menuList = menu.map((children, index) => ({ children, index }))
	const { highlightList, topMenusWithSubMenus, topMenusWithoutSubMenus } = menu
	const { darkMode } = useDarkModeControl()
	const pcHighlightList = highlightList?.filter((item) => item.isPcHighlight)
	const gridSize =
		((pcHighlightList?.length ?? 0) > 0 ? 1 : 0) +
		(topMenusWithSubMenus?.length ?? 0) +
		((topMenusWithoutSubMenus?.length ?? 0) > 0 ? 1 : 0)
	return (
		<CategoryBox
			darkMode={darkMode}
			sx={{
				gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
			}}
		>
			{pcHighlightList && pcHighlightList.length > 0 && (
				<UIFlex
					component={ListItem}
					p="0"
					direction="column"
					align="stretch"
					gap={0}
					sx={{ '&> *:not(:first-child)': { marginTop: theme.pxToRem(12) } }}
				>
					{pcHighlightList?.map((item) => (
						<UIFlex alignItems="flex-start" key={`item-${item.menuIdx}`}>
							<CategoryAnchor link={item.linkUrlInfo} sx={{ fontWeight: 700 }}>
								{item.menuName}
							</CategoryAnchor>
						</UIFlex>
					))}
				</UIFlex>
			)}
			{topMenusWithSubMenus?.map((item) => (
				<UIFlex
					component={ListItem}
					p="0"
					key={`column-${item.menuIdx}`}
					direction="column"
					align="stretch"
					gap={0}
					sx={{ '&> *:not(:first-child)': { marginTop: theme.pxToRem(20) } }}
				>
					<Box>
						<CategoryTitle weight={700}>{item.menuName}</CategoryTitle>
					</Box>
					<Box>
						<UIFlex
							component={List}
							p={0}
							sx={{ '&> *:not(:first-child)': { marginTop: theme.pxToRem(12) } }}
						>
							{item.lowerMenu.map((lowerMenu) => (
								<Box
									component={ListItem}
									p="0"
									display="block"
									minHeight={0}
									fontSize={0}
									key={lowerMenu.menuIdx}
								>
									<CategoryAnchor link={lowerMenu.linkUrlInfo}>
										{lowerMenu.menuName}
									</CategoryAnchor>
								</Box>
							))}
						</UIFlex>
					</Box>
				</UIFlex>
			))}
			{topMenusWithoutSubMenus && topMenusWithoutSubMenus.length && (
				<UIFlex
					component={ListItem}
					p="0"
					direction="column"
					align="stretch"
					gap={0}
					sx={{ '&> *:not(:first-child)': { marginTop: theme.pxToRem(12) } }}
				>
					{topMenusWithoutSubMenus?.map((item) => (
						<UIFlex alignItems="flex-start" key={`item-${item.menuIdx}`}>
							<CategoryAnchor link={item.linkUrlInfo} sx={{ fontWeight: 700 }}>
								{item.menuName}
							</CategoryAnchor>
						</UIFlex>
					))}
				</UIFlex>
			)}
		</CategoryBox>
	)
}

export default SitemapCategory
