import { theme } from '@ui/style/theme'

export const IconArrowTop = ({
	color = theme.color.colBlack,
	direction,
	className,
}: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="20"
			viewBox="0 0 18 20"
			fill="none"
			className={className}
		>
			<path d="M17 10L9 2L1 10" stroke={color} strokeWidth="2.5" />
			<path d="M9 2V20" stroke={color} strokeWidth="2.5" />
		</svg>
	)
}
