'use client'

import { useQuery } from '@tanstack/react-query'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { CommonUseQueryInput } from 'src/shared/types'
import { getCustomerIsLogin } from './getCustomerIsLogin'
import { CustomerIsLoginResponse, customerIsLoginQueryKey } from './types'

export const useCustomerIsLoginQuery = (
	input?: CommonUseQueryInput<CustomerIsLoginResponse, unknown>,
	cookieHeader?: string,
) => {
	const { data, ...rest } = useQuery<CustomerIsLoginResponse>({
		queryKey: [customerIsLoginQueryKey],
		queryFn: async () => {
			const res = await getCustomerIsLogin(cookieHeader)
			return res
		},
		...input?.opt,
	})
	return { data, ...rest }
}

export const prefetchCustomerIsLogin = (cookieHeader?: string) =>
	createPrefetchQuery([customerIsLoginQueryKey], () => getCustomerIsLogin(cookieHeader))
