'use client'

import { keyframes } from '@emotion/react'
import UIFlex from '@components/ui/UIFlex'
import IconLoadingSpinner from 'src/assets/icons/IconLoadingSpinner'
import { useIsLoadPageControl } from '@store/isLoadPage'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import color from '@ui/style/color.theme'

const LoadingFullScreen = () => {
	const { isLoadPage } = useIsLoadPageControl()
	const { darkMode } = useDarkModeControl()
	const spin = keyframes`
		from {
			transform : rotate(0);
		}
		to {
			transform: rotate(360deg);
		}
	`
	return (
		!isLoadPage && (
			<UIFlex
				justifyContent="center"
				alignItems="center"
				width="100%"
				sx={{
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 4000,
					background: darkMode ? color.colBlack : color.colWhite,
					svg: {
						animation: `${spin} 0.5s infinite linear`,
					},
				}}
			>
				<IconLoadingSpinner />
			</UIFlex>
		)
	)
}

export default LoadingFullScreen
