'use client'

import { useCustomerIsLoginQuery } from '@services/api/Customer/IsLogin'
import { useAuthControl } from '@store/auth'
import { useSetCookieHeader } from '@store/cookieHeader'
import { useUserTokenControl } from '@store/userToken'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

interface TokenEventProps {
	userToken: string
	cookieHeader: string
}
const TokenEvent = ({ userToken, cookieHeader }: TokenEventProps) => {
	const pathname = usePathname()
	const searchParams = useSearchParams()
	const { setAuth } = useAuthControl()
	const { setUserToken } = useUserTokenControl()
	const setCookieHeader = useSetCookieHeader()
	const { data: isAuth } = useCustomerIsLoginQuery(undefined, cookieHeader)

	useEffect(() => {
		setCookieHeader(cookieHeader)
	}, [cookieHeader])
	useEffect(() => {
		if (isAuth?.data?.isLogin === true) {
			setAuth(true)
		} else {
			setAuth(false)
		}
		if (userToken) {
			setUserToken(userToken)
		}
	}, [isAuth, userToken, pathname, searchParams])

	return null
}

export default TokenEvent
