import { styled } from '@mui/joy/styles'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import Accordion, { AccordionProps } from '@mui/joy/Accordion'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionSummary from '@mui/joy/AccordionSummary'
import List, { ListProps } from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'

export const CategoryAnchor = styled(UILink)(({ theme }) => ({
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(20),
	fontWeight: 500,
	color: theme.color.colGray5,
}))

interface CategoryBoxProps extends ListProps {
	darkMode?: boolean
}
export const CategoryBox = styled(List, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<CategoryBoxProps>(({ theme, darkMode }) => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(6, 1fr)',
	gap: theme.pxToRem(32),
	paddingTop: theme.pxToRem(24),
	paddingBottom: theme.pxToRem(48),
	borderTop: `1px solid ${darkMode ? '#303030' : theme.color.colGray3}`,
}))

export const CategoryTitle = styled(UIText)(({ theme }) => ({
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(22),
	color: theme.color.colGray5,
}))

export const AccordionAnchor = styled(UILink)(({ theme }) => ({
	display: 'block',
	padding: 0,
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(16),
	fontWeight: 500,
	color: theme.color.colGray4,
}))

interface AccordionBoxProps extends AccordionProps {
	darkMode?: boolean
}
export const AccordionBox = styled(Accordion, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<AccordionBoxProps>(({ theme, darkMode }) => ({
	padding: 0,
	borderTop: `1px solid ${darkMode ? '#303030' : theme.color.colGray2}`,
	borderBottom: 0,
}))

export const AccordionDetailBox = styled(AccordionDetails)(() => ({
	margin: 0,
	padding: 0,
	'& .MuiAccordionDetails-content': {
		margin: 0,
		padding: 0,
	},
}))
export const AccordionDetailContent = styled(List)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(2, 1fr)',
	rowGap: theme.pxToRem(16),
	columnGap: theme.pxToRem(16),
	paddingTop: theme.pxToRem(8),
	paddingBottom: theme.pxToRem(16),
}))
export const AccordionItem = styled(ListItem)(({ theme }) => ({
	margin: 0,
	padding: 0,
	minBlockSize: 'auto',
}))
export const AccordionTitle = styled(AccordionSummary)(({ theme }) => ({
	margin: 0,
	padding: 0,
	'& .MuiAccordionSummary-button': {
		margin: 0,
		padding: `${theme.pxToRem(15)} 0 ${theme.pxToRem(14)}`,
		fontSize: theme.pxToRem(15),
		lineHeight: theme.pxToRem(18),
		fontWeight: 600,
		color: theme.color.colGray5,
		backgroundColor: 'inherit',
		'&:not(.Mui-selected, [aria-selected="true"])': {
			color: theme.color.colGray5,
			backgroundColor: 'inherit',
			'&:active': {
				color: theme.color.colGray5,
				backgroundColor: 'inherit',
			},
			'&:hover': {
				color: theme.color.colGray5,
				backgroundColor: 'inherit',
			},
		},
	},
}))
