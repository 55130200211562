'use client'

import { theme } from '@ui/style'

const IconTabBarVideoNews = ({ color = theme.color.colBlack }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M10 23.2338V8.76619C10 7.9889 10.848 7.50878 11.5145 7.9087L23.5708 15.1425C24.2182 15.5309 24.2182 16.4691 23.5708 16.8575L11.5145 24.0913C10.848 24.4912 10 24.0111 10 23.2338Z"
				stroke={color}
				strokeWidth="1.8"
			/>
		</svg>
	)
}

export default IconTabBarVideoNews
