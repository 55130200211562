'use client'

import Box from '@mui/joy/Box'

import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { useCompanyInfo } from '@services/api/CompanyInfo'
import { useMenuBottom } from '@services/api/Menu/Bottom'
import { useMenuTop } from '@services/api/Menu/Top'

import color from '@ui/style/color.theme'

import ButtonGoToTop from './ButtonGoToTop'
import FooterCopyright from './Copyright'
import FooterMenu from './Menu'
import FooterNotice from './Notice'
import FooterSitemap from './Sitemap'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

interface FooterProps {
	isApp: boolean
}

const Footer = ({ isApp }: FooterProps) => {
	const pathname = usePathname()
	const { darkMode } = useDarkModeControl()
	const { data: menuTop, refetch: refetchMenuTop } = useMenuTop()
	const { data: menuBottom, refetch: refetchMenuBottom } = useMenuBottom()
	const { data: companyInfo, refetch: refetchCompanyInfo } = useCompanyInfo()
	useEffect(() => {
		refetchMenuTop()
		refetchMenuBottom()
		refetchCompanyInfo()
	}, [pathname])
	return (
		<>
			<Box
				component="footer"
				bgcolor={darkMode ? color.colBlack : ''}
				sx={{
					marginTop: 'auto',
					marginBottom: '63px',
				}}
			>
				<FooterNotice />
				{menuTop && <FooterSitemap menu={menuTop} />}
				{menuBottom && <FooterMenu menu={menuBottom} />}
				{companyInfo && <FooterCopyright companyInfo={companyInfo} />}
			</Box>

			<ButtonGoToTop isApp={isApp} />
		</>
	)
}
export default Footer
