import { useMutation, useQuery } from '@tanstack/react-query'
import { ApiResultVoListArticleListResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'

export type GnbNewsflashResponse = CommonResponse<ApiResultVoListArticleListResponseVo>

const path = '/restapi/v1/get/article/gnb-newsflash'
const queryKeyString = () => ['@article/gnb-newsflash'] as const

export const getQuery = async () => {
	return fetchAPI({ path })
}

export const queryGnbNewsflash = () => ({
	queryKey: [...queryKeyString()],
	queryFn: () => getQuery(),
	refetchInterval: 1000 * 60,
})

export const useGnbNewsflash = () => {
	const { data, ...rest } = useQuery(queryGnbNewsflash())

	return { data: data?.data, ...rest }
}

export const useGnbNewsflashMutation = () => {
	const { data, ...rest } = useMutation<ApiResultVoListArticleListResponseVo, Error>({
		mutationKey: queryKeyString(),
		mutationFn: () => getQuery(),
	})
	return { data: data?.data, ...rest }
}

export const prefetchGnbNewsflash = () => createPrefetchQuery(queryKeyString(), () => getQuery())
