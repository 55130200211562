import { useLocalStorage } from '@hooks/useLocalStorage'
import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

interface AutoPlayState {
	autoPlay?: boolean
}

export const autoPlayState = atom<AutoPlayState>({
	key: '#autoPlay',
	default: {
		autoPlay: true,
	},
})

export const useAutoPlayControl = () => {
	const [state, setState] = useRecoilState(autoPlayState)
	const [localAutoPlay, setLocalAutoPlay] = useLocalStorage('auto_play')

	const setAutoPlay = useCallback(
		(value?: boolean) => {
			if (value) {
				setState({ autoPlay: value })
				setLocalAutoPlay(`${value}`)
			} else {
				setState({ autoPlay: false })
				setLocalAutoPlay('false')
			}
		},
		[setState],
	)

	return { autoPlay: state.autoPlay, setAutoPlay }
}
