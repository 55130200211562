'use client'

import { useHandleQueryError } from '@hooks/useHandleQueryError'
import {
	InvalidateQueryFilters,
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
	dehydrate,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental'
import { usePathname } from 'next/navigation'
import { useCallback, useLayoutEffect, useState } from 'react'

export function ReactQueryProvider({ children }: React.PropsWithChildren) {
	const pathname = usePathname()
	const { handleQueryError } = useHandleQueryError()
	const [client] = useState(
		new QueryClient({
			defaultOptions: {
				queries: {
					staleTime: 1000 * 60,
					throwOnError: true,
					retry: 1,
				},
			},
			queryCache: new QueryCache({
				onError: (error, query) => {
					if (query.options.meta?.skipGlobalErrorHandler) {
						return
					}
					handleQueryError({ error })
				},
			}),
			mutationCache: new MutationCache({
				onError: (error, variables, context, mutation) => {
					if (mutation.options.onError) {
						return
					}
					handleQueryError({ error })
				},
			}),
		}),
	)
	const handleInvalidate = useCallback(() => {
		const hydrate = dehydrate(client)
		client.invalidateQueries(hydrate.queries as InvalidateQueryFilters)
	}, [client])
	useLayoutEffect(() => {
		handleInvalidate()
	}, [pathname, handleInvalidate])
	return (
		<QueryClientProvider client={client}>
			<ReactQueryStreamedHydration>{children}</ReactQueryStreamedHydration>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	)
}
