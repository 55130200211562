'use client'

import useIsMounted from '@hooks/useIsMounted'
import pxToRem from '@utils/pxToRem'
import { Box } from '@mui/joy'
import { BarLink, ButtonText } from '../TabBarRoot'
import IconTabBarLive2 from 'src/assets/icons/IconTabBarLive2'
import Lottie from 'react-lottie-player'
import tabBarLiveActiveJson from 'src/assets/icons/lottie/IconTabBarLiveActive.json'
import { UITextProps } from '@components/ui/UIText'
import { useGetIsLive } from '@store/isLive'

const LiveController = ({ textColor }: { textColor: UITextProps['color'] }) => {
	const isMounted = useIsMounted()
	const isLive = useGetIsLive()
	return (
		<BarLink href="/live">
			{isMounted && isLive ? (
				<Box width={pxToRem(32)} height={pxToRem(32)}>
					<Lottie loop animationData={tabBarLiveActiveJson} play />
				</Box>
			) : (
				<IconTabBarLive2 color={textColor} />
			)}
			<ButtonText color={textColor}>라이브</ButtonText>
		</BarLink>
	)
}
export default LiveController
