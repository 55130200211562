import { LoginResponseVo } from '@schemas/non-auth'

declare module '@utils/http/response' {
	interface AppResponseList {
		'/restapi/v1/get/customer/is-login': CustomerIsLoginResponse
	}
}
export type CustomerIsLoginQueryKey = readonly ['@customer/is-login']

export const customerIsLoginQueryKey = '@customer/is-login' as const

export type CustomerIsLoginResponse = CommonResponse<LoginResponseVo>
