import { forwardRef, useEffect } from 'react'

import UISimpleModalRoot, { UISimpleModalRootProps } from './UISimpleModalRoot'
import useHandleAppRefresh from '@hooks/useHandleAppRefresh'

const UISimpleModal = forwardRef<HTMLDivElement, UISimpleModalRootProps>((props, ref) => {
	const handleAppRefresh = useHandleAppRefresh()
	useEffect(() => {
		if (props.open) {
			handleAppRefresh(false)
		}
		return () => {
			handleAppRefresh(true)
		}
	}, [props.open])
	return <UISimpleModalRoot ref={ref} {...props} />
})

export default UISimpleModal
