import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

interface UserTokenState {
	userToken?: string
}

export const userTokenState = atom<UserTokenState>({
	key: '#userToken',
	default: {
		userToken: undefined,
	},
})

export const useUserTokenControl = () => {
	const [state, setState] = useRecoilState(userTokenState)

	const setUserToken = useCallback(
		(value?: string) => {
			if (value) {
				setState({ userToken: value })
			} else {
				setState({ userToken: undefined })
			}
		},
		[setState],
	)

	return { userToken: state.userToken, setUserToken }
}
