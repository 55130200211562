'use client'

import Box from '@mui/joy/Box'
import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import { LogoJoongang } from 'src/assets/icons/LogoJoongang'
import { CompanyResponseVo } from '@schemas/non-auth'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import color from '@ui/style/color.theme'
import CopyrightText from './styled'
import { theme } from '@ui/style'
import pxToRem from '@utils/pxToRem'

export interface FooterCopyrightProps {
	companyInfo: CompanyResponseVo
}
const FooterCopyright = ({ companyInfo }: FooterCopyrightProps) => {
	const { darkMode } = useDarkModeControl()
	return (
		<UIContainer>
			<UIFlex
				flexDirection={{ xs: 'column', xl: 'row' }}
				py={{ xs: 4, md: 6 }}
				sx={{
					'&> *:not(:first-child)': {
						marginLeft: { xl: theme.pxToRem(32) },
						marginTop: { xs: theme.pxToRem(24), lg: theme.pxToRem(32), xl: 0 },
					},
				}}
			>
				<Box
					width={280}
					sx={{
						'& .footer-logo': {
							width: theme.pxToRem(170),
							height: theme.pxToRem(20),
							[theme.breakpoints.up('md')]: {
								width: theme.pxToRem(208),
								height: theme.pxToRem(26),
							},
						},
					}}
				>
					<LogoJoongang
						className="footer-logo"
						color={darkMode ? color.colGray1 : color.colBlack}
					/>
				</Box>
				<CopyrightText sx={{ display: { xs: 'none', lg: 'block' } }}>
					{companyInfo?.pcText}
				</CopyrightText>
				<CopyrightText sx={{ display: { xs: 'block', lg: 'none' } }}>
					{companyInfo?.moText}
				</CopyrightText>
			</UIFlex>
		</UIContainer>
	)
}

export default FooterCopyright
