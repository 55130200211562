import { theme } from '@ui/style'

const IconTabBarMypage = ({ color = theme.color.colBlack }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<ellipse
				cx="16.1893"
				cy="11.1756"
				rx="3.67562"
				ry="3.67562"
				stroke={color}
				strokeWidth="1.8"
			/>
			<path
				d="M7 22.0674C7 19.8582 8.79086 18.0674 11 18.0674H21.3781C23.5872 18.0674 25.3781 19.8582 25.3781 22.0674V24.4997H7V22.0674Z"
				stroke={color}
				strokeWidth="1.8"
			/>
		</svg>
	)
}

export default IconTabBarMypage
