import { styled } from '@mui/joy/styles'
import UIText from '@components/ui/UIText'

const CopyrightText = styled(UIText)(({ theme }) => ({
	fontSize: theme.pxToRem(13),
	lineHeight: theme.pxToRem(22),
	color: theme.color.colGray5,
	whiteSpace: 'break-spaces',
	[theme.breakpoints.up('md')]: {
		lineHeight: theme.pxToRem(26),
	},
}))
export default CopyrightText
