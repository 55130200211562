'use client'

import { sendGAEvent } from '@next/third-parties/google'
import { useIsAppControl } from '@store/isApp'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

interface AnalysisEventProps {
	pathname?: string
}
const AnalysisEvent = ({ pathname }: AnalysisEventProps) => {
	const [deviceType, setDeviceType] = useState<string>('')
	const { appInfo } = useIsAppControl()
	useEffect(() => {
		if (appInfo?.DType === 'A') {
			setDeviceType('AOS')
		} else if (appInfo?.DType === 'I') {
			setDeviceType('IOS')
		} else if (appInfo?.DType === 'W') {
			setDeviceType(isMobile ? 'MOBILE_WEB' : 'PC_WEB')
		}
	}, [appInfo])
	const handleLocationAnalysis = useCallback(() => {
		const { newsAnalysis, document } = window
		const head = document.querySelector('head')
		if (head) {
			const title = head.querySelector('head > title')?.innerHTML ?? ''
			const metaTitle = head.querySelector('meta[name="title"]') as HTMLMetaElement
			if (metaTitle) {
				metaTitle.content = title.replace(' | JTBC뉴스', '')
			} else {
				const metaElement = document.createElement(`meta`)
				metaElement.name = 'title'
				metaElement.content = title
				head.appendChild(metaElement)
			}
		}
		if (newsAnalysis) {
			const { href } = window.location
			if (newsAnalysis.current_path !== href) {
				newsAnalysis.ref = newsAnalysis.current_path
				newsAnalysis.current_path = href
					?.split('&')
					?.filter((str: string) => !str.includes('code='))
					?.join('&')
			}
			newsAnalysis?.init('viewpage', '', '')
		}
	}, [deviceType, pathname])
	useEffect(() => {
		handleLocationAnalysis()
	}, [handleLocationAnalysis])
	useEffect(() => {
		if (deviceType) {
			sendGAEvent('event', 'device_pv', { type: deviceType })
		}
	}, [deviceType, pathname])
	return null
}
export default AnalysisEvent
