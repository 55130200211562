'use client'

import UIContainer from '@components/ui/UIContainer'
import color from '@ui/style/color.theme'
import { useCallback, useState } from 'react'
import Box from '@mui/joy/Box'
import { FrontUrlLinkResponseVo, HierarchyMenuResponseVo } from '@schemas/non-auth'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import {
	MenuBox,
	MenuGroupBox,
	MenuTitle,
	MenuDetail,
	MenuAnchor,
	MenuList,
	MenuItem,
} from './styled'
import { useIsAppControl } from '@store/isApp'
import { theme } from '@ui/style'
import pxToRem from '@utils/pxToRem'
import IconArrowFooter from 'src/assets/icons/IconArrowFooter'

export interface FooterMenuProps {
	menu: Array<HierarchyMenuResponseVo>
}
const FooterMenu = ({ menu }: FooterMenuProps) => {
	const { isApp } = useIsAppControl()
	const { darkMode } = useDarkModeControl()
	const [allExpanded, setAllExpanded] = useState(false)
	const domain = process.env.NEXT_PUBLIC_SITE_URL

	const getLinkInfo = useCallback(
		({ linkCode, linkValue, linkTarget }: FrontUrlLinkResponseVo) => {
			return {
				linkCode,
				linkValue,
				linkTarget:
					isApp && linkCode === 'URL' && !linkValue?.includes(domain)
						? 'blank'
						: linkTarget,
			}
		},
		[isApp],
	)
	const handleChange = (isExpanded: boolean) => {
		setAllExpanded(isExpanded)
	}
	return (
		Array.isArray(menu) && (
			<Box bgcolor={darkMode ? '#303030' : color.colGray1}>
				<UIContainer>
					<Box display={{ xs: 'none', lg: 'block' }}>
						<MenuGroupBox>
							{menu.map((category) => {
								return (
									<MenuBox
										key={`menu-${category.menuIdx}`}
										expanded={allExpanded}
										onChange={(event, isExpanded) => handleChange(isExpanded)}
										darkMode={darkMode}
									>
										<MenuTitle indicator={<IconArrowFooter direction="down" />}>
											{category.menuName}
										</MenuTitle>
										<MenuDetail>
											<MenuList>
												{category.lowerMenu?.map((childMenu) => {
													const linkInfo = getLinkInfo(
														childMenu.linkUrlInfo,
													)
													return (
														<MenuItem
															key={`menu-${category.menuIdx}-${childMenu.menuIdx}`}
														>
															<MenuAnchor
																link={linkInfo}
																sx={{
																	fontWeight:
																		childMenu.menuName ===
																		'개인정보처리방침'
																			? 700
																			: 500,
																}}
															>
																{childMenu.menuName}
															</MenuAnchor>
														</MenuItem>
													)
												})}
											</MenuList>
										</MenuDetail>
									</MenuBox>
								)
							})}
						</MenuGroupBox>
					</Box>
					<Box display={{ xs: 'block', lg: 'none' }}>
						<MenuList>
							{menu?.map((category) => {
								return category.lowerMenu?.map((childMenu) => {
									const linkInfo = getLinkInfo(childMenu.linkUrlInfo)
									return (
										childMenu.isMobileShow && (
											<MenuItem
												key={`menu-${category.menuIdx}-${childMenu.menuIdx}`}
											>
												<MenuAnchor
													link={linkInfo}
													sx={{
														fontWeight:
															childMenu.menuName ===
															'개인정보처리방침'
																? 700
																: 500,
													}}
												>
													{childMenu.menuName}
												</MenuAnchor>
											</MenuItem>
										)
									)
								})
							})}
						</MenuList>
					</Box>
				</UIContainer>
			</Box>
		)
	)
}
export default FooterMenu
