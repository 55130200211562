'use client'

import { useAodControl } from '@store/aod'
import useIsMounted from '@hooks/useIsMounted'
import AodBarRoot, { AodReq } from './web/AodBarRoot'
import AppAodBarRoot from './app/AppAodBarRoot'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { IsAppState } from '@store/isApp'

interface AodBarProps {
	isApp: boolean
}
const AodBar = ({ isApp }: AodBarProps) => {
	const isMounted = useIsMounted()
	const { aod, setAod } = useAodControl()
	const [appStateLocal] = useLocalStorage<string>('app_state')
	const appState = appStateLocal ? (JSON.parse(appStateLocal) as IsAppState) : undefined
	const onClose = () => {
		setAod()
	}
	return (
		isMounted &&
		aod.programIdx &&
		(isApp || appState?.isApp ? (
			<AppAodBarRoot data={aod as AodReq} onClose={onClose} />
		) : (
			<AodBarRoot data={aod as AodReq} onClose={onClose} />
		))
	)
}

export default AodBar
