import { CSSObject } from '@emotion/react'
import { useEffect, useMemo, useRef, useState } from 'react'

// 파이어폭스, 삼성 브라우저 등 iframe 내부 컨텐츠에 zoom이 적용되지 않는 브라우저를 보완하기 위해 iframe을 스타일링 합니다
// ref를 통해 직접적으로 스타일을 적용하거나, 스타일 객체를 직접 반환받아 적용 할 수 있습니다
// wrapper에는 반드시 width, height, overflow:hidden이 있어야 합니다
function useIframeZoom() {
	const wrapperRef = useRef<HTMLElement>(null)
	const iframeRef = useRef<HTMLIFrameElement>(null)
	const [zoomLevel, setZoomLevel] = useState(1)

	useEffect(() => {
		// 화면 너비에 따라 zoomLevel 계산
		const calculateZoomLevel = () => {
			const width = window.innerWidth
			return width >= 393 ? 1 : width / 393
		}

		const updateZoomLevel = () => {
			setZoomLevel(calculateZoomLevel())
		}

		// 초기 zoomLevel 설정
		updateZoomLevel()

		// resize 이벤트 리스너 추가
		window.addEventListener('resize', updateZoomLevel)

		return () => {
			// 컴포넌트 언마운트 시 이벤트 리스너 제거
			window.removeEventListener('resize', updateZoomLevel)
		}
	}, [])

	useEffect(() => {
		const wrapperElement = wrapperRef.current
		const iframeElement = iframeRef.current
		if (wrapperElement && iframeElement) {
			// wrapper에 스타일 적용
			wrapperElement.style.overflow = 'hidden'

			// iframe에 transform 및 크기 조절
			iframeElement.style.transform = zoomLevel === 1 ? '' : `scale(${zoomLevel})`
			iframeElement.style.width = zoomLevel === 1 ? '' : `${100 / zoomLevel}%`
			iframeElement.style.height = zoomLevel === 1 ? '' : `${100 / zoomLevel}%`
			iframeElement.style.transformOrigin = zoomLevel === 1 ? '' : '0 0'

			// zoom 속성 추가 (TypeScript에서 오류 무시)
			// @ts-expect-error : zoom 속성이 없음
			iframeElement.style.zoom = zoomLevel === 1 ? '' : 1 / zoomLevel
		}
	}, [zoomLevel]) // zoomLevel 변경 시 스타일 업데이트
	const iframeStyles = useMemo<CSSObject>(
		() =>
			zoomLevel === 1
				? {}
				: {
						transform: `scale(${zoomLevel})!important`,
						width: `${100 / zoomLevel}%!important`,
						height: `${100 / zoomLevel}%!important`,
						transformOrigin: '0 0!important',
						zoom: 1 / zoomLevel,
				  },
		[zoomLevel],
	)

	return { wrapperRef, iframeRef, iframeStyles }
}

export default useIframeZoom
