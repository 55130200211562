import { ApiResultVoCompanyResponseVo } from '@schemas/non-auth'
import { useQuery } from '@tanstack/react-query'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'

export type CompanyInfoResponse = CommonResponse<ApiResultVoCompanyResponseVo>

const path = '/restapi/v1/get/system/company/info'
const queryKeyString = () => ['@system/company/info'] as const

const getQuery = async (): Promise<ApiResultVoCompanyResponseVo> => {
	return fetchAPI({ path })
}

export const queryCompanyInfo = () => ({
	queryKey: [...queryKeyString()],
	queryFn: () => getQuery(),
})

export const useCompanyInfo = () => {
	const { data, ...rest } = useQuery(queryCompanyInfo())
	return { data: (data as ApiResultVoCompanyResponseVo)?.data, ...rest }
}
export const useCompanyInfoQuery = (
	input?: CommonUseQueryInput<ApiResultVoCompanyResponseVo, unknown>,
) => {
	const { opt } = input ?? {}
	const { data, ...rest } = useQuery({
		...queryCompanyInfo(),
		...opt,
	})
	return { data, ...rest }
}

export const prefetchCompanyInfo = () => createPrefetchQuery(queryKeyString(), () => getQuery())
