import { styled } from '@mui/joy/styles'
import UIText from '@components/ui/UIText'

const NoticeText = styled(UIText)(({ theme }) => ({
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(16),
	fontWeight: 600,
	[theme.breakpoints.up('lg')]: {
		fontSize: theme.pxToRem(16),
		lineHeight: theme.pxToRem(20),
	},
}))

export default NoticeText
