import { theme } from '@ui/style'

const IconTabBarLive2 = ({ color = theme.color.colBlack }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="33"
			height="32"
			viewBox="0 0 33 32"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.6712 6.1712C4.15579 8.68661 2.59998 12.1616 2.59998 16C2.59998 19.8384 4.15579 23.3134 6.6712 25.8288L7.944 24.556C5.75432 22.3663 4.39998 19.3413 4.39998 16C4.39998 12.6587 5.75432 9.63367 7.94399 7.444L6.6712 6.1712ZM25.056 7.44402L26.3288 6.17123C28.8442 8.68663 30.4 12.1616 30.4 16C30.4 19.8384 28.8442 23.3134 26.3288 25.8288L25.056 24.556C27.2456 22.3663 28.6 19.3413 28.6 16C28.6 12.6587 27.2456 9.63369 25.056 7.44402ZM10.2067 9.70674C8.59615 11.3173 7.59998 13.5423 7.59998 16C7.59998 18.4577 8.59615 20.6827 10.2067 22.2933L11.4795 21.0205C10.1947 19.7356 9.39998 17.9606 9.39998 16C9.39998 14.0394 10.1947 12.2644 11.4795 10.9795L10.2067 9.70674ZM21.5204 10.9796L22.7932 9.70676C24.4038 11.3173 25.4 13.5423 25.4 16C25.4 18.4577 24.4038 20.6827 22.7932 22.2932L21.5204 21.0204C22.8053 19.7356 23.6 17.9606 23.6 16C23.6 14.0394 22.8053 12.2644 21.5204 10.9796Z"
				fill={color}
			/>
			<circle cx="16.5" cy="16" r="3" stroke={color} strokeWidth="1.8" />
		</svg>
	)
}

export default IconTabBarLive2
