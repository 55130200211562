import { appInterface } from '@store/isApp'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import IconTabBarNext from 'src/assets/icons/IconTabBarNext'
import IconTabBarPrev from 'src/assets/icons/IconTabBarPrev'
import { BarButton, ButtonText } from '../TabBarRoot'
import { UITextProps } from '@components/ui/UIText'

interface SendAppGetHistoryProps {
	isPrev: boolean
	isNext: boolean
}

const HistoryController = ({ textColor }: { textColor: UITextProps['color'] }) => {
	const pathname = usePathname()
	const nativeEvent = useRecoilValue(appInterface)
	const [isPrev, setIsPrev] = useState<boolean>(false)
	const [isNext, setIsNext] = useState<boolean>(false)

	const handleHistoryBack = () => {
		window.history.back()
	}

	const handleHistoryForward = () => {
		window.history.forward()
	}
	/** Article History 여부 수신 */
	const sendAppGetHistory = ((event: CustomEvent<SendAppGetHistoryProps>) => {
		setIsPrev(event.detail.isPrev)
		setIsNext(event.detail.isNext)
	}) as EventListener
	/** Article History 여부 호출 */
	useEffect(() => {
		nativeEvent({
			key: 'requestAppGetHistory',
			value: null,
		})
	}, [nativeEvent, pathname])

	useEffect(() => {
		window.addEventListener('sendAppGetHistory', sendAppGetHistory)
		return () => {
			window.removeEventListener('sendAppGetHistory', sendAppGetHistory)
		}
	}, [])
	return (
		<>
			<BarButton disabled={!isPrev} onClick={handleHistoryBack}>
				<IconTabBarPrev color={textColor} />
				<ButtonText color={textColor}>이전</ButtonText>
			</BarButton>
			<BarButton disabled={!isNext} onClick={handleHistoryForward}>
				<IconTabBarNext color={textColor} />
				<ButtonText color={textColor}>다음</ButtonText>
			</BarButton>
		</>
	)
}
export default HistoryController
