import { useCallback } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

interface IsMobileState {
	isMobile?: boolean
}

export const isMobileState = atom<IsMobileState>({
	key: '#isMobile',
	default: {
		isMobile: true,
	},
})

export const useIsMobileControl = () => {
	const [state, setState] = useRecoilState(isMobileState)

	const setIsMobile = useCallback(
		(value?: boolean) => {
			if (value) {
				setState({ isMobile: value })
			} else {
				setState({ isMobile: false })
			}
		},
		[setState],
	)

	return { isMobile: state.isMobile, setIsMobile }
}

export const useSetIsMobile = () => {
	const setState = useSetRecoilState(isMobileState)
	return (value?: boolean) => {
		if (value) {
			setState({ isMobile: value })
		} else {
			setState({ isMobile: false })
		}
	}
}

export const useGetIsMobile = () => {
	const state = useRecoilValue(isMobileState)
	return state.isMobile
}
