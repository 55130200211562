import { notFound } from 'next/navigation'

import { useAuthControl } from '@store/auth'
import { APIResultCode } from '../code'

import useModal from '@components/layout/Modal'

export const useHandleQueryError = () => {
	const confirm = useModal()
	const { setAuth } = useAuthControl()

	const handleQueryError = ({ error }: { error: Error }) => {
		let errObj = {} as CommonResponse<unknown>
		try {
			errObj = JSON.parse(error.message)
		} catch (e) {
			errObj = { resultMessage: error.message, resultCode: '', data: null }
		}
		const callNotFount = () => {
			notFound()
		}

		switch (errObj.resultCode) {
			case APIResultCode.LOGIN_REQUIRED:
				setAuth(false)
				confirm.login()
				break

			case APIResultCode.INVALID_URL:
			case APIResultCode.INVALID_METHOD:
				callNotFount()
				break
			default:
				throw new Error(JSON.stringify(errObj))
		}
	}
	return { handleQueryError }
}
