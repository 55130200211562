import { ReactElement, Suspense } from 'react'
import useIsMounted from '@hooks/useIsMounted'

const SSRSuspense = (props: React.ComponentProps<typeof Suspense>) => {
	const { fallback } = props
	const isMounted = useIsMounted()

	if (isMounted) {
		return <Suspense {...props} />
	}
	return fallback as ReactElement
}

export default SSRSuspense
