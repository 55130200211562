import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

interface IsLoadPageState {
	isLoadPage?: boolean
}

export const isLoadPageState = atom<IsLoadPageState>({
	key: '#isLoadPage',
	default: {
		isLoadPage: false,
	},
})

export const useIsLoadPageControl = () => {
	const [state, setState] = useRecoilState(isLoadPageState)

	const setIsLoadPage = useCallback(
		(value?: boolean) => {
			if (value) {
				setState({ isLoadPage: value })
			} else {
				setState({ isLoadPage: false })
			}
		},
		[setState],
	)

	return { isLoadPage: state.isLoadPage, setIsLoadPage }
}
