'use client'

import AppSplash, { AppSplashProps } from '@components/ads/AppSplash'
import useIsMounted from '@hooks/useIsMounted'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { useFCMToken } from '@lib/firebase'
import { useDeviceTokenMutation } from '@services/api/push/DeviceToken/mutation'
import { IsAppState, appInterface, useIsAppControl } from '@store/isApp'
import { useSetIsMobile } from '@store/isMobile'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

interface NativeEventProps extends IsAppState {}

interface SendAppGetHistoryProps {
	pushToken: string
	isPushNoti: boolean
}
interface SendDeviceIsMobileProps {
	isMobile: boolean
}

const splashAds = {
	// STG 코드
	I: 'https://cast-imp.jtbc.co.kr/bid/_SR72QdmQo6enfDQbdOl1A/ewogICJhbGciIDogIkhTMjU2IiwKICAidHlwIiA6ICJKV1QiCn0.ewogICJpZCIgOiAiIiwKICAiZGV2aWNlIiA6IHsKICAgICJvcyIgOiAiSU9TIiwKICAgICJtb2RlbCIgOiAiaXBob25lIiwKICAgICJoIiA6IDAsCiAgICAiZGV2aWNldHlwZSIgOiAxLAogICAgImlmYSIgOiAiIiwKICAgICJ3IiA6IDAsCiAgICAibG10IiA6IDEsCiAgICAiaXAiIDogIjEyNy4wLjAuMSIKICB9LAogICJpbXAiIDogWwogICAgewogICAgICAibmF0aXZlIiA6IHsKICAgICAgICAicmVxdWVzdCIgOiAiIiwKICAgICAgICAiZXh0IiA6IHsKICAgICAgICAgICJzbG90cyIgOiAxCiAgICAgICAgfSwKICAgICAgICAidmVyIiA6ICIxLjAiCiAgICAgIH0KICAgIH0KICBdLAogICJhcHAiIDogewogICAgImNvbnRlbnQiIDogewogICAgICAiZXh0IiA6IHsKCiAgICAgIH0sCiAgICAgICJjYXQiIDogWwogICAgICAgICIwMSIKICAgICAgXSwKICAgICAgImxpdmVzdHJlYW0iIDogMAogICAgfSwKICAgICJuYW1lIiA6ICJKVEJDTkVXUyIKICB9LAogICJ1c2VyIiA6IHsKICAgICJnZW5kZXIiIDogIk8iLAogICAgImV4dCIgOiB7CiAgICAgICJhZ2UiIDogOTkKICAgIH0sCiAgICAieW9iIiA6IDAsCiAgICAiY3VzdG9tZGF0YSIgOiAiIgogIH0KfQ.PDTRLCYZGuPnfScNDqZ7cH_Jtcq2piQjmJEYv9o29t4',
	A: 'https://cast-imp.jtbc.co.kr/bid/dRDoR2rkQDyQNBuA2mXstw/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOnsibmFtZSI6IkpUQkNORVdTIiwiY29udGVudCI6eyJleHQiOnt9LCJjYXQiOlsiMDEiXSwibGl2ZXN0cmVhbSI6MH19LCJpZCI6IiIsImRldmljZSI6eyJvcyI6IkFORFJPSUQiLCJkZXZpY2V0eXBlIjoxLCJoIjowLCJtb2RlbCI6IkFuZHJvaWQiLCJpZmEiOiIiLCJsbXQiOjEsInciOjAsImlwIjoiMTI3LjAuMC4xIn0sImltcCI6W3sibmF0aXZlIjp7ImV4dCI6eyJzbG90cyI6MX0sInZlciI6IjEuMCIsInJlcXVlc3QiOiIifX1dLCJ1c2VyIjp7InlvYiI6MCwiZXh0Ijp7ImFnZSI6OTl9LCJnZW50ZXIiOiJPIiwiY3VzdG9tZGF0YSI6Ijc3MUY0NDFCMkE3OUE2QjE2MDAyMkE2NzFDNDdCNTkyIn19.zRVC2xwZwLssAR3xbhulpgl3_ixCrcthoaB3N4EgbXs=',
	// 운영 코드
	// I: 'https://cast-imp.jtbc.co.kr/bid/5DJBBq2VTG2L8qTXC3HBIg/ewogICJhbGciIDogIkhTMjU2IiwKICAidHlwIiA6ICJKV1QiCn0.ewogICJpZCIgOiAiIiwKICAiZGV2aWNlIiA6IHsKICAgICJvcyIgOiAiSU9TIiwKICAgICJtb2RlbCIgOiAiaXBob25lIiwKICAgICJoIiA6IDAsCiAgICAiZGV2aWNldHlwZSIgOiAxLAogICAgImlmYSIgOiAiIiwKICAgICJ3IiA6IDAsCiAgICAibG10IiA6IDEsCiAgICAiaXAiIDogIjEyNy4wLjAuMSIKICB9LAogICJpbXAiIDogWwogICAgewogICAgICAibmF0aXZlIiA6IHsKICAgICAgICAicmVxdWVzdCIgOiAiIiwKICAgICAgICAiZXh0IiA6IHsKICAgICAgICAgICJzbG90cyIgOiAxCiAgICAgICAgfSwKICAgICAgICAidmVyIiA6ICIxLjAiCiAgICAgIH0KICAgIH0KICBdLAogICJhcHAiIDogewogICAgImNvbnRlbnQiIDogewogICAgICAiZXh0IiA6IHsKCiAgICAgIH0sCiAgICAgICJjYXQiIDogWwogICAgICAgICIwMSIKICAgICAgXSwKICAgICAgImxpdmVzdHJlYW0iIDogMAogICAgfSwKICAgICJuYW1lIiA6ICJKVEJDTkVXUyIKICB9LAogICJ1c2VyIiA6IHsKICAgICJnZW5kZXIiIDogIk8iLAogICAgImV4dCIgOiB7CiAgICAgICJhZ2UiIDogOTkKICAgIH0sCiAgICAieW9iIiA6IDAsCiAgICAiY3VzdG9tZGF0YSIgOiAiIgogIH0KfQ.PDTRLCYZGuPnfScNDqZ7cH_Jtcq2piQjmJEYv9o29t4',
	// A: 'https://cast-imp.jtbc.co.kr/bid/RMNZVNYtTRyWHQHVGEh3HQ/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOnsibmFtZSI6IkpUQkNORVdTIiwiY29udGVudCI6eyJleHQiOnt9LCJjYXQiOlsiMDEiXSwibGl2ZXN0cmVhbSI6MH19LCJpZCI6IiIsImRldmljZSI6eyJvcyI6IkFORFJPSUQiLCJkZXZpY2V0eXBlIjoxLCJoIjowLCJtb2RlbCI6IkFuZHJvaWQiLCJpZmEiOiIiLCJsbXQiOjEsInciOjAsImlwIjoiMTI3LjAuMC4xIn0sImltcCI6W3sibmF0aXZlIjp7ImV4dCI6eyJzbG90cyI6MX0sInZlciI6IjEuMCIsInJlcXVlc3QiOiIifX1dLCJ1c2VyIjp7InlvYiI6MCwiZXh0Ijp7ImFnZSI6OTl9LCJnZW50ZXIiOiJPIiwiY3VzdG9tZGF0YSI6Ijc3MUY0NDFCMkE3OUE2QjE2MDAyMkE2NzFDNDdCNTkyIn19.zRVC2xwZwLssAR3xbhulpgl3_ixCrcthoaB3N4EgbXs=',
}
const NativeEvent = ({ isApp = false, appInfo }: NativeEventProps) => {
	const nativeEvent = useRecoilValue(appInterface)
	const setIsMobile = useSetIsMobile()
	const isMounted = useIsMounted()
	const [sendNativeEvent, setSendNativeEvent] = useState<boolean>(false)
	const [alarmUseYn] = useLocalStorage<boolean>('alarm_use_yn')
	const [appStateLocal, setAppState] = useLocalStorage<string | undefined>('app_state')
	const [newsAlarmYn, setNewsAlarmYn] = useLocalStorage<boolean>('news_alarm_yn')
	const [subNewsAlarmYn, setSubNewsAlarmYn] = useLocalStorage<boolean>('sub_news_alarm_yn')
	const [replyAlarmYn, setReplyAlarmYn] = useLocalStorage<boolean>('reply_alarm_yn')
	const [streamingAlarmYn, setStreamingAlarmYn] = useLocalStorage<boolean>('streaming_alarm_yn')
	const [etiquetteYn, setEtiquetteYn] = useLocalStorage<boolean>('etiquette_yn')
	const [networkYn, setNetworkYn] = useLocalStorage<boolean>('network_yn')
	const [splashAd, setSplashAd] = useState<Array<AppSplashProps> | null>(null)
	const { setIsApp, setAppInfo } = useIsAppControl()
	const fcmToken: string | null = useFCMToken()
	const { mutateAsync: updateDeviceToken } = useDeviceTokenMutation()

	const isYN = (value: boolean) => (value ? 'Y' : 'N')

	const updateAppDeviceToken = useCallback(
		({ pushToken, isPushNoti }: SendAppGetHistoryProps) => {
			const appState = appStateLocal ? JSON.parse(appStateLocal) : undefined

			if (appState?.appInfo?.DType) {
				const useYN = isYN(isPushNoti)
				const isChangedAlarm = alarmUseYn !== isPushNoti
				updateDeviceToken({
					deviceToken: pushToken,
					dtype: appState?.appInfo?.DType === 'I' ? 'IOS' : 'AOS',
					alarmUseYn: useYN,
					newsAlarmYn:
						newsAlarmYn === undefined || isChangedAlarm ? useYN : isYN(newsAlarmYn),
					subNewsAlarmYn:
						subNewsAlarmYn === undefined || isChangedAlarm
							? useYN
							: isYN(subNewsAlarmYn),
					replyAlarmYn:
						replyAlarmYn === undefined || isChangedAlarm ? useYN : isYN(replyAlarmYn),
					streamingAlarmYn:
						streamingAlarmYn === undefined || isChangedAlarm
							? useYN
							: isYN(streamingAlarmYn),
				})
				if (newsAlarmYn === undefined) {
					setNewsAlarmYn(isPushNoti)
				}
				if (subNewsAlarmYn === undefined) {
					setSubNewsAlarmYn(isPushNoti)
				}
				if (replyAlarmYn === undefined) {
					setReplyAlarmYn(isPushNoti)
				}
				if (streamingAlarmYn === undefined) {
					setStreamingAlarmYn(isPushNoti)
				}
			}
		},
		[appStateLocal, alarmUseYn, newsAlarmYn, subNewsAlarmYn, replyAlarmYn, streamingAlarmYn],
	)
	/** APP Push Token, 알림 권한 정보 수신 */
	const sendAppPushInfo = ((event: CustomEvent<SendAppGetHistoryProps>) => {
		setAppInfo(event.detail)
		updateAppDeviceToken(event.detail)
	}) as EventListener
	/** APP 모바일 데이터 사용 여부 수신 */
	const sendDeviceIsMobile = ((event: CustomEvent<SendDeviceIsMobileProps>) => {
		const { isMobile } = event.detail
		setIsMobile(isMobile)
	}) as EventListener
	const getSplashAd = async (DType: 'I' | 'A') => {
		const response = await fetch(splashAds[DType], {})
		const data = await response.json()
		if (!data.error) {
			setSplashAd(Array.isArray(data) ? data : [data])
		}
	}
	useEffect(() => {
		if (appInfo && appInfo.DType !== 'W' && !splashAd) {
			getSplashAd(appInfo.DType)
		}
	}, [appInfo, splashAd])
	useEffect(() => {
		let state: IsAppState | undefined
		if (isApp) {
			state = { isApp, appInfo }
			setIsApp(state)
			setAppState(JSON.stringify(state))
		} else if (appStateLocal) {
			state = JSON.parse(appStateLocal)
			setIsApp(state)
			setSendNativeEvent(false)
		}
		if (!state) {
			if (fcmToken) {
				const useYN = Notification.permission === 'granted'
				setIsApp({
					isApp: false,
					appInfo: {
						DType: 'W',
						pushToken: fcmToken,
						isPushNoti: useYN,
					},
				})
				updateDeviceToken({
					deviceToken: fcmToken,
					dtype: 'WEB',
					alarmUseYn: isYN(useYN),
					newsAlarmYn: isYN(useYN),
					subNewsAlarmYn: isYN(useYN),
					replyAlarmYn: isYN(useYN),
					streamingAlarmYn: isYN(useYN),
				})
			}
		}
		window.addEventListener('sendAppPushInfo', sendAppPushInfo)
		window.addEventListener('sendDeviceIsMobile', sendDeviceIsMobile)
		return () => {
			window.removeEventListener('sendAppPushInfo', sendAppPushInfo)
			window.removeEventListener('sendDeviceIsMobile', sendDeviceIsMobile)
		}
	}, [appStateLocal, fcmToken])

	useEffect(() => {
		if (isMounted) {
			if (etiquetteYn === undefined) {
				setEtiquetteYn(false)
			}
			if (networkYn === undefined) {
				setNetworkYn(false)
			}
		}
	}, [isMounted, etiquetteYn, networkYn])

	useEffect(() => {
		if (!sendNativeEvent && isMounted) {
			setTimeout(() => {
				/** APP Push Token, 알림 권한 정보 호출 */
				nativeEvent({
					key: 'requestAppPushInfo',
					value: null,
				})
				/** APP 모바일 데이터 사용 여부 호출 */
				nativeEvent({
					key: 'requestDeviceIsMobile',
					value: null,
				})
				setSendNativeEvent(true)
			}, 100)
		}
	}, [nativeEvent, sendNativeEvent, isMounted])
	// AD 비노출 처리
	// return (
	// 	splashAd &&
	// 	splashAd.map((adData) => <AppSplash key={`ad-${adData.image_file}`} {...adData} />)
	// )
	return null
}
export default NativeEvent
