import { styled } from '@mui/joy/styles'
import Accordion, { AccordionProps } from '@mui/joy/Accordion'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionGroup from '@mui/joy/AccordionGroup'
import AccordionSummary from '@mui/joy/AccordionSummary'
import UILink from '@components/ui/UILink'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import { marginLeft, marginTop } from 'styled-system'
import pxToRem from '@utils/pxToRem'

export const MenuGroupBox = styled(AccordionGroup)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(6, 1fr)',
	gap: theme.pxToRem(32),
	paddingTop: theme.pxToRem(14),
	paddingBottom: theme.pxToRem(14),
}))

interface MenuBoxProps extends AccordionProps {
	darkMode?: boolean
}
export const MenuBox = styled(Accordion, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<MenuBoxProps>(({ theme, darkMode }) => ({
	display: 'flex',
	margin: 0,
	padding: `${theme.pxToRem(2)} 0`,
	minHeight: 'auto',
	border: 0,
	'&:not([data-last-child])': {
		borderRight: `1px solid ${darkMode ? '#3F3F3F' : theme.color.colGray3}`,
	},
}))

export const MenuTitle = styled(AccordionSummary)(({ theme }) => ({
	margin: 0,
	padding: 0,
	minHeight: 'auto',
	'& .MuiAccordionSummary-button': {
		margin: 0,
		padding: 0,
		minHeight: theme.pxToRem(20),
		border: 0,
		fontSize: theme.pxToRem(13),
		lineHeight: theme.pxToRem(16),
		fontWeight: 500,
		color: theme.color.colGray5,
		backgroundColor: 'inherit',
		justifyContent: 'flex-start',
		gap: 0,
		'&:not(.Mui-selected, [aria-selected="true"])': {
			color: theme.color.colGray5,
			backgroundColor: 'inherit',
			'&:active': {
				color: theme.color.colGray5,
				backgroundColor: 'inherit',
			},
			'&:hover': {
				color: theme.color.colGray5,
				backgroundColor: 'inherit',
			},
		},
		[theme.breakpoints.up('xl')]: {
			fontSize: theme.pxToRem(14),
			lineHeight: theme.pxToRem(20),
		},
	},
}))

export const MenuDetail = styled(AccordionDetails)(({ theme }) => ({
	margin: 0,
	padding: 0,
	'& .MuiAccordionDetails-content': {
		margin: 0,
		padding: 0,
		paddingRight: theme.pxToRem(32),
	},
}))

export const MenuAnchor = styled(UILink)(({ theme }) => ({
	display: 'block',
	padding: 0,
	fontSize: theme.pxToRem(13),
	lineHeight: theme.pxToRem(16),
	fontWeight: 500,
	color: theme.color.colGray5,
	[theme.breakpoints.up('xl')]: {
		fontSize: theme.pxToRem(14),
		lineHeight: theme.pxToRem(20),
	},
}))

export const MenuList = styled(List)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(2, 1fr)',
	paddingTop: theme.pxToRem(16),
	paddingBottom: theme.pxToRem(16),
	marginLeft: theme.pxToRem(-16),
	'&>*.MuiListItem-root': {
		marginTop: theme.pxToRem(12),
		marginLeft: theme.pxToRem(16),
	},
	'&>*.MuiListItem-root:nth-child(1), &>*.MuiListItem-root:nth-child(2)': {
		marginTop: 0,
	},

	[theme.breakpoints.up('lg')]: {
		gridTemplateColumns: 'repeat(1, 1fr)',
		paddingTop: theme.pxToRem(12),
		paddingBottom: 0,
		marginLeft: 0,
		'&>*.MuiListItem-root': {
			marginLeft: 0,
			marginTop: theme.pxToRem(12),
		},
		'&>*.MuiListItem-root:nth-child(1)': {
			marginTop: 0,
		},
		'&>*.MuiListItem-root:nth-child(2)': {
			marginTop: theme.pxToRem(12),
		},
	},
}))

export const MenuItem = styled(ListItem)(({ theme }) => ({
	margin: 0,
	padding: 0,
	alignItems: 'flex-start',
	minBlockSize: 'auto',
}))
